"use client";

import { MuiThemeProvider } from "@/components/core/MuiThemeProvider";
import { Provider as JotaiProvider } from "jotai";
import React, { useEffect, useState } from "react";
import GlobalUI from "@/components/core/GlobalUI";
import "@/app/globals.css";
import "@/app/globals.mui.css";
import { WebViewCommunicator } from "@/components/core/WebViewCommunicator";
import { AuthStateChangeAction } from "../../components/core/AuthStateChangeAction";
import { useLastTabSurvive } from "@/functions/hooks/useLastTabSurvive";
import { useCalculateViewportHeight } from "../../functions/hooks/useCalculateViewportHeight";
import { useRequestStartWebviewToNative } from "../../functions/hooks/useRequestStartWebviewToNative";
import { useSentryAmplitudeInit } from "../../functions/hooks/useSentrySetUser";
import { getCookie } from "@/functions/cookie";
import { addBreadcrumb, captureException } from "@sentry/nextjs";
import { createClient } from "@/supabase/utils/client";
import { getUserType } from "@/functions/getUserType";
import { NAMED } from "@/types";
function Layout({
  children
}: {
  children: React.ReactNode;
}) {
  const [visible, setVisible] = useState(false);
  useLastTabSurvive();
  useCalculateViewportHeight();
  useRequestStartWebviewToNative();
  useSentryAmplitudeInit();
  useEffect(() => {
    setVisible(true);
  }, []);
  return <div className={`${visible ? "" : "hidden"}`} style={{
    animationName: "fadeIn",
    animationDuration: "1s"
  }} data-sentry-component="Layout" data-sentry-source-file="layout.tsx">
        <MuiThemeProvider data-sentry-element="MuiThemeProvider" data-sentry-source-file="layout.tsx">
          {children}
          <GlobalUI data-sentry-element="GlobalUI" data-sentry-source-file="layout.tsx"></GlobalUI>
          <WebViewCommunicator data-sentry-element="WebViewCommunicator" data-sentry-source-file="layout.tsx" />
          <AuthStateChangeAction data-sentry-element="AuthStateChangeAction" data-sentry-source-file="layout.tsx" />
        </MuiThemeProvider>
    </div>;
}
export default function JotaiRoot({
  children
}: {
  children: React.ReactNode;
}) {
  return <JotaiProvider data-sentry-element="JotaiProvider" data-sentry-component="JotaiRoot" data-sentry-source-file="layout.tsx"><Layout data-sentry-element="Layout" data-sentry-source-file="layout.tsx">{children}</Layout></JotaiProvider>;
}