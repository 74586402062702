import React, { Suspense, lazy } from 'react';
const Snackbar = lazy(() => import('@/components/common/Snackbar'));
const ConfirmDialog = lazy(() => import('@/components/common/ConfirmDialog'));
const Login = lazy(() => import('@/components/layout/Login'));
const Loading = lazy(() => import('@/components/common/Loading'));
const FileUploader = lazy(() => import('../home/logined/fileUploader'));
export default function GlobalUI() {
  return <>
            <Snackbar data-sentry-element="Snackbar" data-sentry-source-file="GlobalUI.tsx" />
            <Loading data-sentry-element="Loading" data-sentry-source-file="GlobalUI.tsx" />
            {/* <Analytics /> */}
            <ConfirmDialog data-sentry-element="ConfirmDialog" data-sentry-source-file="GlobalUI.tsx" />
            <Login data-sentry-element="Login" data-sentry-source-file="GlobalUI.tsx" />
            <FileUploader data-sentry-element="FileUploader" data-sentry-source-file="GlobalUI.tsx" />
        </>;
}