"use client";

import { useAtom } from "jotai";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { darkModeState } from "@/jotai";
import { grey } from "@mui/material/colors";
// import { DevTools } from "jotai-devtools";
export const MuiThemeProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [darkMode, setDarkMode] = useAtom(darkModeState);
  const themeOption = {
    palette: {
      // @ts-ignore
      mode: darkMode ? "dark" : "light",
      // 기타 테마 설정
      primary: grey
    },
    typography: {
      fontFamily: "inherit"
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: grey[100],
            '&.Mui-checked': {
              color: grey[200]
            }
          }
        }
      }
    }
  };
  // @ts-ignore
  const darkTheme = createTheme(themeOption);
  return <ThemeProvider theme={darkTheme} data-sentry-element="ThemeProvider" data-sentry-component="MuiThemeProvider" data-sentry-source-file="MuiThemeProvider.tsx">{children}</ThemeProvider>;
};